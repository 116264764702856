module.exports = [
  {
    locale: 'es',
    label: 'Español',
  },
  {
    locale: 'en',
    label: 'English',
    default: true,
  },
];
